<template>
  <div>
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <el-form :inline="true" :model="search" class="demo-form-inline" ref="searchForm">
            <el-form-item :label="$t('version.title')" prop="version">
              <el-input :placeholder="$t('version.title')" v-model="search.version"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="loadData" type="primary">{{ $t('device.inquire') }}</el-button>
              <el-button @click="resetFun" type="primary">{{ $t('device.reset') }}</el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-button @click="addUser" type="primary" v-if="$store.state.pageAction.indexOf('device.add')> -1">
          {{ $t('device.add') }}
        </el-button>
      </template>
      <el-table
          :data="appData"
          border
          stripe
          style="width: 100%"
      >
        <el-table-column :label="$t('version.title')" width="70" prop="version"></el-table-column>
        <el-table-column :label="$t('version.update')" prop="content"></el-table-column>
        <el-table-column :label="$t('version.download')" prop="download"></el-table-column>
        <el-table-column :label="$t('order.status')" width="70" prop="status_title"></el-table-column>
        <el-table-column :label="$t('device.creation_time')" prop="create_at" width="160"></el-table-column>
        <el-table-column :label="$t('device.operating')" width="140">
          <template #default="scope">
            <el-button @click="handleEdit(scope.row)" size="small"
                       v-if="$store.state.pageAction.indexOf('device.edit')> -1">{{ $t('device.edit') }}
            </el-button>
            <el-button @click="handleStatus(scope.row)" size="small"
                       v-if="$store.state.pageAction.indexOf('device.status')> -1">
              {{ scope.row.status === 1 ? $t('role.disable') : $t('role.enable') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-drawer v-model="addPop" direction="rtl" size="40%">
        <template v-slot:title>
          <h4>{{ $t('version.add') }}</h4>
        </template>
        <template v-slot:default>
          <el-form :model="addForm" ref="addForm" :rules="rules">
            <el-form-item :label="$t('version.title')" label-width="120px" prop="version">
              <el-input v-model="addForm.version"></el-input>
            </el-form-item>
            <el-form-item :label="$t('version.update')" label-width="120px" prop="content">
              <el-input type="textarea" :row="6" v-model="addForm.content"></el-input>
            </el-form-item>
            <el-form-item :label="$t('version.download')" label-width="120px" prop="download">
              <el-upload
                  class="upload-img"
                  action="/common/upload"
                  ref="upload"
                  :auto-upload="true"
                  accept=".apk"
                  :on-success="uploadSuccess"
              >
                <el-input v-model="addForm.download" readonly></el-input>
                <el-button type="primary" style="margin-left:20px">{{
                    addForm.download === '' ? $t('version.upload') : $t('version.re_upload')
                  }}
                </el-button>
              </el-upload>
            </el-form-item>
            <el-form-item :label="$t('order.status')" label-width="120px" prop="status">
              <el-radio :label="1" v-model="addForm.status">{{ $t('role.enable') }}</el-radio>
              <el-radio :label="2" v-model="addForm.status">{{ $t('role.disable') }}</el-radio>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:footer>
          <div style="flex: auto">
            <el-button @click="addPop = false">{{ $t('device.cancel') }}</el-button>
            <el-button @click="handleSubmit" type="primary">{{ $t('device.confirm') }}</el-button>
          </div>
        </template>
      </el-drawer>
      <el-pagination
          :page-size="search.size"
          :page-sizes="[10, 20, 50]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          fixed="right"
          layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </el-card>
  </div>
</template>
<script>
import version from '../../model/version'

export default {
  name: 'version',
  data() {
    return {
      search: {
        version: '',
        page: 1,
        size: 10
      },
      total: 0,
      appData: [],
      addPop: false,
      addForm: {
        id: '',
        version: '',
        content: '',
        download: '',
        status: 1
      },
      rules: {
        version: [
          {required: true, message: this.$t('version.version_empty'), trigger: 'blur'}
        ],
        content: [
          {required: true, message: this.$t('version.update_empty'), trigger: 'blur'}
        ],
        download: [
          {required: true, message: this.$t('version.apk_empty'), trigger: 'blur'}
        ]
      }
    };
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const ret = await version.gets(this.search)
      if (ret.code === 200) {
        this.appData = ret.data.list
        this.total = ret.data.count
      }
    },
    async handleSubmit() {
      await this.$refs.addForm.validate(async valid => {
        if (valid) {
          const params = JSON.parse(JSON.stringify(this.addForm))
          const ret = await version.handle(params)
          if (ret.code === 200) {
            this.addPop = false
            this.$message.success(ret.message)
            await this.loadData()
          } else {
            this.$message.warning(ret.message)
          }
        } else {
          return false
        }
      });
    },
    async handleStatus(row) {
      const ret = await version.status({
        id: row.id,
        status: row.status === 1 ? 2 : 1
      })
      if (ret.code === 200) {
        this.$message.success(ret.message)
        await this.loadData()
      } else {
        this.$message.warning(ret.message)
      }
    },
    uploadSuccess(response) {
      this.addForm.download = response.data
    },
    resetFun() {
      this.search.version = ''
      this.loadData()
    },
    addUser() {
      this.addPop = true
      setTimeout(() => {
        this.$refs.addForm.resetFields()
        this.addForm = {
          id: '',
          version: '',
          content: '',
          download: '',
          status: 1
        }
      })
    },
    handleEdit(row) {
      this.addPop = true
      setTimeout(() => {
        this.$refs.addForm.resetFields()
        this.addForm = JSON.parse(JSON.stringify(row))
      })
    },
    handleSizeChange(val) {
      this.search.size = val
      this.loadData()
    },
    handleCurrentChange(val) {
      this.search.page = val
      this.loadData()
    }
  }
};
</script>
<style scoped>
.demo-form-inline {
  margin-top: 20px;
}

.card-header {
  display: flex;
  align-items: center;
}

.el-pagination {
  margin: 30px 0px;
  float: right;
}
</style>
