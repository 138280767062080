import request from '../utils/request'

export default {
  async gets (data) {   // 获取列表
    return await request('/v1/version/gets', data, 'POST')
  },
  async handle (data) {    // 新增/修改信息
    return await request('/v1/version/handle', data, 'POST')
  },
  async status (data) {  // 修改状态
    return await request('/v1/version/status', data, 'POST')
  }
}
